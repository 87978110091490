import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#c10000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FAFAFA',
      main: '#9f9fa0',
      dark: '#191716',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: [
      'Lato'
    ].join(','),
  },
  shape: {
    //borderRadius: 30,
  },
});

export default theme;